*, :before, :after {
    box-sizing: border-box;
}
.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 auto;

    h1, p { margin-bottom: 20px }
    .buttons {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }    
}